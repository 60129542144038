"use client";

import {
  Options,
  documentToReactComponents,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document } from "@contentful/rich-text-types";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { useContext } from "react";

import theme from "../../../styles/globalTheme";
import { ContentSectionThemeContext } from "../../../utils/contexts";

interface RichTextRendererProps {
  richTextDocument: Document;
  options?: Options;
}

// options to define how to render content elements in the DOM
const simpleOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography
        variant="body1"
        marginBottom={useTheme().spacing(2)}
        whiteSpace="pre-wrap"
        color={useContext(ContentSectionThemeContext).textColor}
        sx={{
          wordBreak: "break-word",
          a: {
            color:
              useContext(ContentSectionThemeContext).textColor ??
              theme.palette.green.dark,
          },
        }}
      >
        {children}
      </Typography>
    ),
  },
};

/**
 * Helper component to render rich text field that can only render simple text
 *
 * @param {RichTextRendererProps} props
 * @returns JSX.Element
 */
const SimpleTextRenderer = ({
  richTextDocument,
  options,
}: RichTextRendererProps) => {
  return (
    <>{documentToReactComponents(richTextDocument, options || simpleOptions)}</>
  );
};
export default SimpleTextRenderer;
