import Head from "next/head";

/**
 * This component injects elements to `<head>` of the page.
 * Contains elments shared by all pages wrapped into `<PageLayout />` component.
 *
 * @returns JSX.Element
 */
const SharedHead = () => (
  <Head key="shared-page-layout">
    <link rel="icon" href="/favicon.png" type="image/png" sizes="32x32" />
  </Head>
);

export default SharedHead;
