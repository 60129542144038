"use client";

import {
  Options,
  documentToReactComponents,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { Asset } from "contentful";

import Image from "next/legacy/image";
import Link from "next/link";

import React from "react";

import {
  IGlobalFooterFields,
  ILink,
} from "../../../@types/generated/contentful";
import useLink from "../../../hooks/useLink";
import { withHttps } from "../../../utils/helperFunctions";

interface IFooterLinkList {
  categoryName?: string;
  links?: ILink[];
  horizontal?: boolean;
}
interface GlobalFooterProps {
  globalFooter: IGlobalFooterFields;
  logo?: Asset;
}

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography variant="body2">{children}</Typography>
    ),
  },
};

/**
 * Renders a list of links with caption
 * @param {string} categoryName
 * @param {IFooterLinkList} links
 * @returns JSX.Element
 */
const FooterLinkList = ({
  categoryName,
  links,
  horizontal = false,
}: IFooterLinkList) => {
  const { getLinkProps } = useLink();
  const theme = useTheme();
  return (
    <Box className="footer-link-list">
      <Typography
        variant="body2"
        component="div"
        sx={{ mb: 1, color: theme.components?.Footer.color, fontWeight: 600 }}
      >
        {categoryName}
      </Typography>
      <List
        sx={{
          display: horizontal ? "flex" : "block",
        }}
      >
        {links?.map((link: ILink) => {
          const { linkTarget, shopifyProductFields, ...rest } = getLinkProps(
            link.fields
          );
          return (
            <ListItem key={link.fields.internal} sx={{ pl: 0 }}>
              <ListItemText>
                <Link
                  {...rest}
                  target={linkTarget}
                  style={{
                    color: theme.components?.Footer.color,
                    textDecoration: "none",
                    fontSize: 14,
                  }}
                >
                  {rest.logo && (
                    <Image
                      width={24}
                      height={24}
                      src={withHttps(rest.logo.fields.file.url.substring(1))}
                      alt={rest.label}
                    />
                  )}
                  {!rest.logo && rest.label}
                </Link>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

/**
 * First level footer styles
 */
const StyledFooter = styled("footer")(({ theme }) => {
  return {
    backgroundColor: theme.components?.Footer.backgroundColor,
    color: theme.components?.Footer.color,
    padding: theme.spacing(5, 2, 3, 2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    ".MuiTypography-root": {
      color: theme.components?.Footer.color,
    },
  };
});

/**
 * The Footer that is displayed on every Page, Homepage & Coursepage
 *
 * @param {GlobalFooterProps} props
 * @param {Asset} logo
 * @returns JSX.Element
 */
const Footer = ({ globalFooter, logo }: GlobalFooterProps) => {
  const {
    categoryOneName,
    categoryOneList,
    categoryTwoName,
    categoryTwoList,
    footerText,
  } = globalFooter;

  const logoUrl = logo?.fields.file.url.length
    ? withHttps(logo.fields.file.url)
    : "/hh_logo_cream.svg";

  const theme = useTheme();

  return (
    <StyledFooter>
      <Grid container rowSpacing={4} maxWidth="lg" marginX="auto">
        <Grid item xs={12} md={5}>
          {logoUrl && (
            <Box
              sx={{
                width: "100%",
                maxWidth: "144px",
                position: "relative",
              }}
            >
              <Image
                src={logoUrl}
                width={logo?.fields.file.details.image?.width || 110}
                height={logo?.fields.file.details.image?.height || 55}
                alt="Logo"
              />
            </Box>
          )}
          <Box
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            {documentToReactComponents(footerText, options)}
          </Box>
        </Grid>
        <Grid
          item
          display="flex"
          columnGap={2}
          xs={12}
          md={7}
          sx={{
            justifyContent: { xs: "start", md: "end" },
            overflow: "hidden",
          }}
        >
          <Box>
            {!!categoryOneList && (
              <FooterLinkList
                categoryName={categoryOneName}
                links={categoryOneList}
              />
            )}
          </Box>
          <Box>
            {!!categoryTwoList && (
              <FooterLinkList
                horizontal
                categoryName={categoryTwoName}
                links={categoryTwoList}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </StyledFooter>
  );
};

export default Footer;
