import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const NavHighlight = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "5px",
        borderTopRightRadius: "5px",
        borderTopLeftRadius: "5px",
        backgroundColor: theme.components?.NavHighlight.backgroundColor,
      }}
    ></Box>
  );
};
