import { Link as MUILink } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";

import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";

import React from "react";

import {
  ContentfulNavigation,
  ContentfulNavigationPageReference,
} from "../../../../@types/contentfulNavigation";
import { getHrefByPageTypeAndSlug } from "../../../../utils/helperFunctions";
import { saveCoursepageSource } from "../../../../utils/localstorage/navigationTracking";
import { COURSE_LISTS } from "../../../../utils/trackingEvents/select-item";

const Menu = dynamic(() => import("@mui/material/Menu"));
interface ISubmenu {
  item: ContentfulNavigation;
  anchorElNav: HTMLElement;
  open: boolean;
  onClose: () => void;
}

export interface ISubmenuItem {
  pageReference?: ContentfulNavigationPageReference;
  openNewTab?: boolean;
  internal: string;
  label: string;
  externalLink?: string;
  onItemClick: () => void;
}

/**
 * SubmenuItem is used to compose Submenu
 *
 * @param {string} internal - the internal prop of the submenuItem
 * @param {ContentfulNavigationPageReference} pageReference - optional pageReference object
 * @param {string} label - the label of the submenuItem
 * @param {Function} onItemClick - callback function triggered on click of submenuItem
 * @returns JSX.Element
 */
const SubmenuItem = ({
  internal,
  label,
  pageReference,
  openNewTab,
  externalLink,
  onItemClick,
}: ISubmenuItem) => {
  const theme = useTheme();
  const colors = theme.palette;
  const path = usePathname();
  let fullHref = "/";
  let selected = false;
  if (pageReference) {
    const { __typename, slug, isRecipe } =
      pageReference as ContentfulNavigationPageReference;
    fullHref = getHrefByPageTypeAndSlug(__typename, slug, isRecipe);
    selected = path === fullHref;
  }
  fullHref = externalLink ? externalLink : fullHref;

  return (
    <MenuItem
      key={internal}
      sx={{
        p: 0,
        mx: 0,
        borderRadius: "100px",
        "&:hover, &:active, &:focus, &.Mui-selected": {
          backgroundColor: theme.components?.Navigation.backgroundColorSelected,
        },
      }}
      onClick={onItemClick}
      selected={selected}
    >
      <MUILink
        variant="body2"
        color={theme.components?.Navigation.colorListItem}
        sx={{
          p: theme.spacing(2),
          mx: theme.spacing(1),
          display: "block",
          width: "100%",
        }}
        target={openNewTab ? "_blank" : "_self"}
        underline="none"
        onClick={onItemClick}
        href={fullHref}
      >
        {label}
      </MUILink>
    </MenuItem>
  );
};

/**
 * Submenu for desktop navigation
 *
 * @param {ContentfulNavigation} item - a single navigation item which links to the submenu
 * @param {HTMLElement} anchorElNav - HTML element of navigation item
 * @param {boolean} open - is submenu dislayed
 * @param {Function} onClose - callback function triggered on close of submenu
 * @returns JSX.Element
 */
const Submenu = ({ item, anchorElNav, open, onClose }: ISubmenu) => {
  const { internal } = item;
  return (
    <Menu id={internal} anchorEl={anchorElNav} open={open} onClose={onClose}>
      {item.subNavCollection.items.map((item: ContentfulNavigation) => (
        <SubmenuItem
          key={`submenu-${item.internal}`}
          {...item}
          onItemClick={() => {
            saveCoursepageSource(COURSE_LISTS.HEADER);
            onClose();
          }}
        />
      ))}
    </Menu>
  );
};

export default Submenu;
