import { ShoppingCartOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  LinearProgress,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Theme, keyframes, styled, useTheme } from "@mui/material/styles";

import Image from "next/image";

import React, { useCallback } from "react";
import { useContext, useEffect, useRef, useState } from "react";

import { ShopifyProductWithVariant } from "../../@types/shopifyProductWithVariant";
import {
  Cart,
  CartLineItemWithProduct,
  getCartItemAmount,
  getCartItems,
  getCartSumTotal,
  getCartSumTotalWithoutDiscounts,
  isCartEmpty,
} from "../../utils/cart/cart";
import { CartContext, CartContextType } from "../../utils/contexts";
import { formatPrice } from "../../utils/helperFunctions";
import { createCheckoutApiURL } from "../../utils/shopify/createCart";
import { pushDataLayerBeginCheckout } from "../../utils/trackingEvents/begin-checkout";
import CartAmountToggle from "./amountToggle";

function CartNavItem() {
  const theme = useTheme();

  const cartContext = useContext(CartContext);
  const { cart } = cartContext;
  const [amount, setAmount] = useState(getCartItemAmount(cart));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [cartAmountChange, setCartAmountChange] = useState<number>(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isCartAmountChange = useRef(false);

  const [order, setOrder] = useState(Object.keys(cart.items));

  const itemsSorted = Object.keys(cart.items).sort(
    (keyA, keyB) => order.indexOf(keyA) - order.indexOf(keyB)
  );

  useEffect(() => {
    const newAmount = getCartItemAmount(cart);
    setAmount(newAmount);
    if (newAmount > 0) {
      setCartAmountChange(1);
    }
  }, []);

  useEffect(() => {
    const newAmount = getCartItemAmount(cart);
    const difference = newAmount - amount;

    if (amount == 0 && newAmount > 0) {
      setCartAmountChange(1);
    }
    if (
      cart &&
      cart.updateStatus == "LOCAL" &&
      difference !== 0 &&
      !isCartAmountChange.current
    ) {
      setCartAmountChange(difference);
    }

    const newOrder = order
      .slice()
      .filter((key) => cart.items.hasOwnProperty(key));
    Object.keys(cart.items)
      .filter((key) => newOrder.indexOf(key) == -1)
      .forEach((key) => newOrder.push(key));
    setOrder(newOrder);
  }, [cart]);

  useEffect(() => {
    if (cartAmountChange == 0) {
      isCartAmountChange.current = false;
      const newAmount = getCartItemAmount(cart);
      setAmount(newAmount);
    }
  }, [cartAmountChange]);

  const handleAnimationEnd = useCallback(() => {
    setCartAmountChange(0);
  }, []);

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setIsDrawerOpen(open);
    },
    []
  );

  const drawerWidth = 376;
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "flex-start",
    alignContent: "flex-start",
    padding: theme.spacing(1, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: "80px!important",
    flexDirection: "column",
  }));
  return !cart ? (
    <></>
  ) : (
    <>
      <IconButton
        sx={{
          py: 0,
          boxSizing: "border-box",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        size="large"
        aria-label={"Einkaufswagen"}
        aria-controls="menu-cart"
        aria-haspopup="true"
        onClick={toggleDrawer(true)}
        color="inherit"
      >
        <ShoppingCartOutlined
          sx={{ boxSizing: "border-box", width: "32px", height: "32px" }}
        />
        {amount > 0 && (
          <CartNavNumber
            amount={amount}
            isSmallScreen={isSmallScreen}
            theme={theme}
          />
        )}

        {cartAmountChange != 0 && (
          <CartAnimationBox
            animationtype={cartAmountChange < 0 ? "remove" : "add"}
            onAnimationEnd={handleAnimationEnd}
          />
        )}
      </IconButton>
      <nav>
        <Drawer
          id="menu-cart"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          anchor="right"
          variant="temporary"
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            width: drawerWidth,
            maxWidth: "100vw",
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              maxWidth: "100vw",
            },
          }}
        >
          <DrawerHeader>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={toggleDrawer(false)} size="large">
                <CloseIcon sx={{ color: theme.palette.softBlack.dark }} />
              </IconButton>
            </Box>

            <Typography variant="h3" sx={{ textAlign: "left", pl: 1 }}>
              Einkaufswagen
            </Typography>
          </DrawerHeader>
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {CartDrawerContent({ cart, itemsSorted, theme, cartContext })}

            {CartDrawerFooter({ cart, theme })}
          </Box>
        </Drawer>
      </nav>
    </>
  );
}
const CartNavNumber = (props: {
  amount: number;
  theme: Theme;
  isSmallScreen: boolean;
}) => {
  const { amount, theme, isSmallScreen } = props;

  return (
    <Box
      id="cartItemNumber"
      sx={{
        position: "absolute",
        top: "32px",
        left: "44px",
        transform: "translate(-50%, -50%)",

        width: { xs: "25px", md: "29px" },
        height: { xs: "25px", md: "29px" },
        borderRadius: "32px",

        textAlign: "center",
        textDecoration: "none!important",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        boxSizing: "border-box",
        pointerEvents: "none",

        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Typography color={"white"} variant={isSmallScreen ? "body2" : "body1"}>
        {amount}
      </Typography>
    </Box>
  );
};

function CartDrawerContent(props: {
  cart: Cart;
  itemsSorted: string[];
  theme: Theme;
  cartContext: CartContextType;
}) {
  const { cart, itemsSorted, theme, cartContext } = props;

  return (
    <Box sx={{ maxWidth: "100%" }}>
      {itemsSorted.map((variantId, i) => {
        const cartItem = cart.items[variantId];

        return (
          <CartDrawerItem
            key={`cartItem${i}`}
            cartItem={cartItem}
            cartContext={cartContext}
            theme={theme}
          />
        );
      })}
    </Box>
  );
}

function CartDrawerFooter(props: { cart: Cart; theme: Theme }) {
  const { cart, theme } = props;

  const totalCartAmount = getCartSumTotal(cart);
  const totalCartAmountWithoutDiscount = getCartSumTotalWithoutDiscounts(cart);
  return (
    <Box
      sx={{
        width: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        pb: theme.spacing(3),
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: theme.spacing(2),
          mb: theme.spacing(2),
          borderBottom: `0px solid ${theme.palette.softBlack.light}`,
          borderTop: `2px solid ${theme.palette.softBlack.light}`,
        }}
      >
        {cart.isUpdatingPrices && (
          <LinearProgress
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
            }}
          />
        )}
        {totalCartAmountWithoutDiscount > totalCartAmount && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4" sx={{ fontWeight: "normal" }}>
              Rabatt
            </Typography>
            {!cart.isUpdatingPrices && (
              <Typography variant="h4" sx={{ fontWeight: "normal" }}>
                -
                {formatPrice(
                  totalCartAmountWithoutDiscount - totalCartAmount,
                  "EUR"
                )}
              </Typography>
            )}
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" sx={{ fontWeight: "normal" }}>
            Zwischensumme
          </Typography>
          {!cart.isUpdatingPrices && (
            <Typography variant="h4" sx={{ fontWeight: "normal" }}>
              {formatPrice(totalCartAmount, "EUR")}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          boxSizing: "border-box",
          p: theme.spacing(2),
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          disabled={isCartEmpty(cart)}
          variant="contained"
          href={
            cart.checkoutURL ||
            createCheckoutApiURL(
              getCartItems(cart).map((product) => product.variantId)
            )
          }
          onClick={(e) => writeTrackingEvents(getCartItems(cart))}
        >
          Zur Kasse
        </Button>
      </Box>
      <Link
        variant="body1"
        sx={{
          color: theme.palette.softBlack.main,
          textDecoration: "none",
          textAlign: "center",
          my: theme.spacing(1),
        }}
        href="/warenkorb"
      >
        Warenkorb ansehen
      </Link>
    </Box>
  );
}

const cartAddKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translate(-25px,-20px) rotate(180deg) scale(1);
  }
  30% {
    opacity: 1;
    transform: translate(0px,-20px) rotate(0deg) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(0px,-20px) rotate(0deg) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(0px,0px) rotate(0deg) scale(0.3);
    
  }
  `;
const cartRemoveKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translate(0px,0px) rotate(0deg) scale(.3);
  }
  30% {
    opacity: 1;
    transform: translate(0px,-20px) rotate(0deg) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(0px,-20px) rotate(0deg) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(50px,-20px) rotate(360deg) scale(1);
    
  }
  `;

const CartAnimationBox = styled(Box)<{ animationtype: "add" | "remove" }>`
  animation: ${(props) =>
      props.animationtype === "remove" ? cartRemoveKeyframes : cartAddKeyframes}
    0.6s linear 0s 1 forwards;
  position: absolute;
  top: 5px;
  left: 19px;
  background-color: transparent;
  border: 3px solid black;
  box-sizing: border-box;
  color: black;
  opacity: 0;
  width: 17px;
  height: 17px;
  border-radius: 4px;
`;

export const CartDrawerItem = (props: {
  cartItem: CartLineItemWithProduct;
  theme: Theme;
  cartContext: CartContextType;
}) => {
  const { cartItem, theme, cartContext } = props;
  const { setCartItemAmount, deleteCartItem, cart } = cartContext;

  const isDeleted = cartItem.amount <= 0;
  return !cartItem || isDeleted || !cartItem.product ? (
    <></>
  ) : (
    <Box
      key={cartItem.product.variantId}
      sx={{
        position: "relative",

        p: theme.spacing(2),
        pt: theme.spacing(1),

        borderBottom: `1px solid ${theme.palette.softBlack.light}`,
        borderTop: "none",
        transition: "0.4s all ease-out",
        overflow: "hidden",

        "&:last-child": {
          borderBottom: `none`,
        },
      }}
    >
      {/* <Box
        sx={{
          overflow: "hidden",
          transition: "0.5s all ease-out",
          position: isDeleted ? "relative" : "absolute",
          transform: isDeleted ? "translate(0px,0px)" : "translate(-500px,0px)",
          pt: theme.spacing(1),
        }}
      >
        <IconButton
          size="small"
          color="inherit"
          onClick={() => deleteCartItem(cartItem)}
          sx={{
            position: "absolute",
            top: theme.spacing(1),
            right: theme.spacing(0),
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant={"body1"}
          sx={{
            maxWidth: "80%",
          }}
        >
          <s>{cartItem.product.title}</s>
        </Typography>

        <Button
          variant="contained"
          onClick={() => {
            setCartItemAmount(cartItem, 1);
          }}
          sx={{
            mt: theme.spacing(2),
          }}
        >
          Wiederherstellen
        </Button>
      </Box> */}
      <Box
        className="cartItemContainer"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          justifyContent: "space-between",
          pb: theme.spacing(2),

          transition: "0.5s all ease-out",

          transform: isDeleted ? "translate(300px,0px)" : "translate(0px,0px)",
          opacity: isDeleted ? 0 : 1,
          position: isDeleted ? "absolute" : "relative",
          top: theme.spacing(2),
          pointerEvents: isDeleted ? "none" : "all",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {cartItem.product.media && (
            <Box
              sx={{
                height: "64px",
                width: "64px",

                filter: isDeleted ? "grayScale(1)" : "grayScale(0)",
                position: "relative",
                mb: theme.spacing(2),
                borderRadius: "10px",
                overflow: "hidden",
                backgroundColor: "rgba(0,0,0,0.1)",
                border: "1px solid " + theme.palette.softBlack.light,
              }}
            >
              <Image
                sizes="64px"
                style={{ objectFit: "contain" }}
                fill
                alt={cartItem.product.media.alt}
                src={cartItem.product.media.previewUrl}
              ></Image>
            </Box>
          )}
          <Box>
            <CartAmountToggle
              amount={cartItem.amount}
              setCartAmount={(amount) => {
                setCartItemAmount(cartItem, amount);
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            ml: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "stretch",
            flex: 1,
          }}
        >
          <Typography variant="body1">{cartItem.product.title}</Typography>

          {cartItem && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                position: "relative",
              }}
            >
              {cartItem.cost.subtotalAmount > cartItem.cost.totalAmount && (
                <Typography
                  variant="body1"
                  sx={{
                    color: cart.isUpdatingPrices
                      ? theme.palette.softBlack.light
                      : theme.palette.softBlack.main,
                    textAlign: "right",
                  }}
                >
                  <s>{formatPrice(cartItem.cost.subtotalAmount, "EUR")}</s>
                </Typography>
              )}
              <Typography
                variant="body1"
                sx={{
                  color: cart.isUpdatingPrices
                    ? theme.palette.softBlack.light
                    : theme.palette.softBlack.main,
                  textAlign: "right",
                }}
              >
                {formatPrice(cartItem.cost.totalAmount, "EUR")}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(CartNavItem);

function writeTrackingEvents(productList: ShopifyProductWithVariant[]): void {
  pushDataLayerBeginCheckout(productList);
}
