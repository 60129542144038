import {
  ShopifyProductWithVariant,
  ShopifyProductWithVariantPrice,
} from "../../@types/shopifyProductWithVariant";

export interface DataLayerItem {
  item_id: string;
  item_name: string;
  item_variant: string;
  discount: number;
  price: ShopifyProductWithVariantPrice;
}

export function clearDataLayer() {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
  }
}

export function getDataLayerItem(
  shopifyProduct: ShopifyProductWithVariant
): DataLayerItem {
  const minPrice = shopifyProduct?.minPrice;
  const price = shopifyProduct.price
    ? shopifyProduct.price.amount
    : minPrice.amount;
  const matched = /[^/]*$/.exec(shopifyProduct?.id);
  const productTitle = shopifyProduct?.title;
  const id = matched ? matched[0] : "";
  return {
    item_id: id,
    item_name: productTitle,
    item_variant: shopifyProduct?.variantTitle,
    discount: 0,
    price,
  };
}
