import { Asset } from "contentful";

import {
  ICoursePageFields,
  ILinkFields,
  IPageFields,
} from "../@types/generated/contentful";
import { getHrefByPageTypeAndSlug } from "../utils/helperFunctions";
import {
  IProductFieldsModified,
  createCheckoutApiURL,
  getVariantIdsToCheckout,
} from "../utils/shopify/createCart";

interface LinkProps {
  href: string;
  linkTarget: string;
  label: string;
  logo: Asset | undefined;
  shopifyProductFields: IProductFieldsModified | null;
}

/**
 * provides getLinkProps() to Components
 *
 * @returns { Function }
 */
const useLink = () => {
  /**
   * Prepares the fields of Link Contenttypes for the LinkComponent
   *
   * @param {ILinkFields } link
   * @returns linkProps
   */
  const getLinkProps = (link: ILinkFields): LinkProps => {
    const {
      externalLink,
      internalLink,
      target,
      label,
      logo,
      anchorLink,
      discount,
    } = link;
    const isInternal = !externalLink;
    let href = "/";
    let shopifyProductFields = null;

    if (isInternal) {
      const pageType = internalLink?.sys.contentType?.sys.id || "homepage";
      if (anchorLink) {
        href = "#" + anchorLink.fields.internal.replace(/\s/g, "");
      } else if (pageType === "product" || pageType === "productVariant") {
        shopifyProductFields = internalLink?.fields as IProductFieldsModified;
        if (shopifyProductFields) {
          href = createCheckoutApiURL(
            getVariantIdsToCheckout(shopifyProductFields),
            discount
          );
        }
      } else if (internalLink?.fields !== undefined) {
        const slug = (internalLink?.fields as ICoursePageFields).slug || "";
        const isRecipe =
          internalLink?.sys.contentType?.sys.id == "page" &&
          (internalLink.fields as IPageFields).isRecipe;
        href = getHrefByPageTypeAndSlug(pageType, slug, isRecipe);
      }
    } else {
      href = externalLink;
    }

    const linkTarget = target ? "_blank" : "_self";

    return { href, linkTarget, label, logo, shopifyProductFields };
  };

  return { getLinkProps };
};

export default useLink;
