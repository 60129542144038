import { IProductFieldsModified } from "../shopify/createCart";
import { DataLayerItem } from "../trackingEvents/common";

/**
 * Format a number and a currency code into a properly internationalized price.
 *
 * @param {number} amount
 * @param {string} currencyCode
 * @returns {string}
 */
export function formatPrice(amount: number, currencyCode: string = "EUR") {
  return Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: currencyCode,
  }).format(amount);
}

/**
 * Format the contents of a contentful date field to a date string in the form DD-MM-JJJJ
 * @param date string from contentful date field
 */
export function formatContentfulDate(date: string) {
  const dateObj = new Date(date);
  return `${dateObj.getDate()}. ${getMonthName(
    dateObj.getMonth()
  )} ${dateObj.getFullYear()}`;
}
function getMonthName(month: number) {
  return (
    [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ][month] || ""
  );
}

/**
 * Prepends url with http if it's absent
 * @param {string} url
 * @returns {string}
 */
export function withHttps(url: string) {
  const prefixed = !/^https?:\/\//i.test(url) ? `https://${url}` : url;
  return prefixed.replace("////", "//");
}

/**
 * Returns a contentful-specific href depending on given pageType
 *
 * @param {string} pageType - contentful content type
 * @param {string} slug - the slug of the page
 * @returns {string}
 */
export function getHrefByPageTypeAndSlug(
  pageType: string,
  slug: string,
  isRecipe = false
) {
  let href = "/";
  // pageType can be e.g. CoursePage or coursePage --> lowercase the first letter
  const lowerCaseType = pageType[0].toLowerCase().concat(pageType.slice(1));

  switch (lowerCaseType) {
    case "coursePage":
      href = `/programme/${slug}`;
      break;
    case "productDetailPage":
      href = `/produkt/${slug}`;
      break;
    case "page":
      href = `${isRecipe ? "/rezept" : ""}/${slug}`;
      break;
    default:
      href = "/";
      break;
  }
  return href;
}

/**
 *
 * Temporary solution to write begin_checkout even if a checkoutlink in the form
 * // https://shop.hellohealth.de/cart/<variantId>:<amount>
 * is used.
 */
export function getShopifyProductsFromHref(href: string) {
  const productInfos = {
    "44765250421001": {
      discount: 0,
      item_id: "8361699868937",
      item_name: "Doc Fleck Ernährungstagebuch",
      item_variant: "Default Title",
      price: { amount: "30.00" },
    },
    "44497855578377": {
      discount: 0,
      item_id: "8267619696905",
      item_name: "17 Schlankbooster-Rezepte zum Abnehmen von Doc Fleck",
      item_variant: "Default Title",
      price: { amount: "16.99" },
    },
    "44337835639049": {
      discount: 0,
      item_id: "8207144517897",
      item_name: "Fett weg - die Länger-Leben-Formel von Doc Fleck",
      item_variant: "Default Title",
      price: { amount: "229.0" },
    },
    "44497963614473": {
      discount: 0,
      item_id: "8267654136073",
      item_name: "Doc Fleck Workbook",
      item_variant: "Default Title",
      price: { amount: "30.00" },
    },
  } as any;
  const checkoutProducts = [] as DataLayerItem[];

  if (href && href.trim().startsWith("https://shop.hellohealth.de/cart/")) {
    let productString = href
      .split("https://shop.hellohealth.de/cart/")[1]
      .split("?")[0];

    let productStrings = productString.split(",");

    productStrings.forEach((splitString) => {
      let [itemId, amount] = splitString.split(":");
      if (itemId && productInfos.hasOwnProperty(itemId)) {
        for (let i = 0; i < parseInt(amount); i++) {
          checkoutProducts.push({ ...productInfos[itemId] });
        }
      }
    });
  }
  return checkoutProducts;
}

export function getShopifyProductsWithExtras(product: IProductFieldsModified) {
  const checkoutProducts = [product.shopifyProduct];
  if (product.extraShopifyProduct) {
    checkoutProducts.push(product.extraShopifyProduct);
  }
  if (product.extraShopifyProduct2) {
    checkoutProducts.push(product.extraShopifyProduct2);
  }
  return checkoutProducts;
}
export const getIdFromString = (internalName: string) => {
  return (internalName || "").replace(/\s/g, "");
};
