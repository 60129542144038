import { CourseListType } from "../trackingEvents/select-item";

/**
 * Helper functions to read/write localstorage item used to determine how a coursepage was accessed (through header / homepage / coursepage overview)
 *
 */

interface PageSourceItem {
  timestamp: number;
  listType: CourseListType;
}

const LOCAL_STORAGE_PATH = "coursePageSource";

export const saveCoursepageSource = (courseList: CourseListType): void => {
  if (typeof window !== "undefined" && window.dataLayer) {
    const item = {
      timestamp: Date.now(),
      listType: courseList,
    };
    window.localStorage.setItem(LOCAL_STORAGE_PATH, JSON.stringify(item));
  }
};
export const getCoursePageSource = (): CourseListType | undefined => {
  if (typeof window !== "undefined" && window.dataLayer) {
    const now = Date.now();
    const lsString = window.localStorage.getItem(LOCAL_STORAGE_PATH);
    let lsItem = null;
    if (lsString != null) {
      lsItem = JSON.parse(lsString) as PageSourceItem;
    }
    // If we find a source list-item in localstorage less than 10 seconds old we assume that's where the user is coming from.
    if (lsItem && now - lsItem.timestamp < 10 * 1000) {
      return lsItem.listType;
    }
  }
  return undefined;
};
