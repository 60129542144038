import Container from "@mui/material/Container";
import { Asset } from "contentful";

import Head from "next/head";
import Script from "next/script";

import React from "react";

import { ContentfulNavigation } from "../../../@types/contentfulNavigation";
import {
  IGlobalFooterFields,
  IMetadataFields,
} from "../../../@types/generated/contentful";
import { ReducedSiteSettingFields } from "../../../utils/page/getJointPageProperties";
import { BAS_KAST_TENANT_NAME } from "../../../utils/tenant-names";
import Banner from "../../banner";
import Footer from "../footer";
import SharedHead from "../head";
import Navigation from "../navigation";

interface PageLayoutProps {
  /**
   * Will be rendered into <main> tag
   * Prefer using 1-n <Container /> as wrapper for children
   */
  children: React.ReactNode | React.ReactNode[];
  /**
   * Title of the page displayed in the browser's title bar or in the page's tab.
   */
  title: string;
  /**
   * The result of a GraphQL query to Contentful to display the navigation.
   */
  navigation: ContentfulNavigation;
  /**
   * static footer props
   */
  footer: IGlobalFooterFields | null;
  /**
   * Logo displayed in the header of the page.
   */
  logoHeader?: Asset;
  /**
   * Logo displayed in the footer of the page
   */
  logoFooter?: Asset;
  /**
   * Seo Metadata of each page
   */
  metadata: IMetadataFields;

  /**
   * Should a banner be displayed?
   */
  bannerVisible?: boolean;

  /**
   * Icon of the banner
   */
  bannerIcon?: ReducedSiteSettingFields["bannerIcon"];

  /**
   * Text of the banner
   */
  bannerText?: ReducedSiteSettingFields["bannerText"];

  enableFading?: boolean;

  noNavigation?: boolean;

  tenant?: string;
}

/**
 * This component defines the structure of HTML document. Use it as wrapper for pages.
 * Contains Head, Header, Navigation, `children` as Content and Footer
 *
 * @param {ReactNode | ReactNode[]} children - content of page
 * @param title specific title of the page
 * @returns JSX.Element
 */
const PageLayout = ({
  children,
  title,
  footer,
  navigation,
  logoHeader,
  logoFooter,
  metadata,
  enableFading = false,
  bannerVisible,
  bannerText,
  bannerIcon,
  noNavigation,
  tenant,
}: PageLayoutProps) => {
  /**
   * check for empty fields and prepare for output
   * @param metadata
   * @returns { string }
   */
  const prepareMetadata = (metadata: IMetadataFields) => {
    const og = metadata.ogMetadata?.fields;
    const twitter = metadata.twitterMetadata?.fields;

    const setRobots = () => {
      if (metadata.noIndex && metadata.noFollow) {
        return "noindex, nofollow";
      }

      if (metadata.noIndex) {
        return "noindex";
      }

      if (metadata.noFollow) {
        return "nofollow";
      }

      return "";
    };

    const robots = setRobots();

    const metatag = {
      title: metadata.title,
      description: metadata.description,
      keywords: metadata.keywords?.join(", "),
      robots,
      ogTitle: og?.title ?? metadata.title,
      ogDescription: og?.description ?? metadata.description,
      ogType: og?.type ?? "",
      ogImage: og?.image ?? twitter?.image ?? "",
      twitterCard: twitter?.card ?? "",
      twitterTitle: twitter?.title ?? metadata.title,
      twitterDescription: twitter?.description ?? metadata.description,
      twitterImage: twitter?.image ?? og?.image ?? "",
    };

    return metatag;
  };

  const metatags = prepareMetadata(metadata);

  return (
    <>
      <Head key={title}>
        <title>{title}</title>
        <meta name="title" content={metatags.title} />
        <meta name="description" content={metatags.description} />
        {metatags.keywords && (
          <meta name="keywords" content={metatags.keywords} />
        )}
        <meta name="robots" content={metatags.robots} />
        <meta property="og:title" content={metatags.ogTitle} />
        <meta property="og:description" content={metatags.ogDescription} />
        {metatags.ogType && (
          <meta property="og:type" content={metatags.ogType} />
        )}
        {metatags.ogImage && (
          <meta property="og:image" content={metatags.ogImage} />
        )}
        {metatags.twitterCard && (
          <meta property="twitter:card" content={metatags.twitterCard} />
        )}
        <meta property="twitter:title" content={metatags.twitterTitle} />
        <meta
          property="twitter:description"
          content={metatags.twitterDescription}
        />
        {metatags.twitterImage && (
          <meta property="twitter:image" content={metatags.twitterImage} />
        )}
      </Head>
      <SharedHead />
      <Script
        id="varify"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.varify = window.varify || {};
          window.varify.iid = 1118;
          `,
        }}
      />

      <Script src="https://app.varify.io/varify.js"></Script>
      {bannerVisible && <Banner text={bannerText} icon={bannerIcon} />}
      <Container
        className="page-wrapper"
        maxWidth={false}
        style={{
          minHeight: "100vh",
          display: "flex",
          flexWrap: "nowrap",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {
          <Navigation
            noNavigation={!!noNavigation}
            enableFading={enableFading}
            items={navigation}
            logo={logoHeader}
            logoAlt={tenant == BAS_KAST_TENANT_NAME ? logoHeader : logoFooter}
            tenant={tenant}
          />
        }
        <main style={{ flex: "1", overflow: "hidden" }}>{children}</main>
        {footer && <Footer globalFooter={footer} logo={logoFooter} />}
      </Container>
    </>
  );
};

export default PageLayout;
