import { Link as MUILink, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { usePathname } from "next/navigation";

import { Fragment, useState } from "react";

import {
  ContentfulNavigation,
  ContentfulNavigationPageReference,
} from "../../../../@types/contentfulNavigation";
import { getHrefByPageTypeAndSlug } from "../../../../utils/helperFunctions";
import Submenu, { ISubmenuItem } from "../submenu";
import { NavHighlight } from "./nav-hightlight";

interface INavDesktop {
  items: ContentfulNavigation;
  isWhiteText: boolean;
}

/**
 * Desktop Navigation
 *
 * @param {ContentfulNavigation} items - navigation items
 * @returns JSX.Element
 */
const NavDesktop = ({ items, isWhiteText }: INavDesktop) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: HTMLElement;
  }>({});

  const handleItemClick = (
    index: number,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({});
  };
  const path = usePathname();

  return items.subNavCollection?.items.map(
    (item: ContentfulNavigation, index: number) => {
      if (item.subNavCollection.items.length > 0) {
        let fullHref = "/";
        let selected = false;

        item.subNavCollection.items.find((navItem: ISubmenuItem) => {
          if (navItem.pageReference) {
            const { __typename, slug, isRecipe } =
              navItem.pageReference as ContentfulNavigationPageReference;
            fullHref = getHrefByPageTypeAndSlug(__typename, slug, isRecipe);
            if (path === fullHref) {
              selected = true;
            }
          }
        });

        return (
          <Fragment key={`desktop-${item.internal}`}>
            <MenuItem onClick={(e) => handleItemClick(index, e)}>
              {selected && <NavHighlight />}
              <Typography
                sx={{
                  color: isWhiteText
                    ? theme.components?.Navigation.colorFaded
                    : theme.components?.Navigation.color,
                }}
              >
                {item.label}
              </Typography>
            </MenuItem>
            <Submenu
              key={item.label}
              item={item}
              anchorElNav={anchorEl && anchorEl[index]}
              open={anchorEl && Boolean(anchorEl[index])}
              onClose={handleClose}
            />
          </Fragment>
        );
      }
      let fullHref = "/";
      let selected = false;

      if (item.pageReference) {
        const { __typename, slug, isRecipe } =
          item.pageReference as ContentfulNavigationPageReference;
        fullHref = getHrefByPageTypeAndSlug(__typename, slug, isRecipe);
        if (
          path?.replace("/docfleck/", "/").replace("/baskast/", "/") ===
          fullHref
        ) {
          selected = true;
        }
      }
      return (
        <NavItem
          selected={selected}
          key={`desktop-${item.internal}`}
          item={item}
          isWhiteText={isWhiteText}
        />
      );
    }
  );
};

interface NavItemProps {
  item: ContentfulNavigation;
  isWhiteText: boolean;
  selected: boolean;
}
const NavItem = ({ item, isWhiteText, selected }: NavItemProps) => {
  const theme = useTheme();

  const { pageReference, externalLink, openNewTab, internal } = item;
  let fullHref = "/";
  const textColor = isWhiteText
    ? theme.components?.Navigation.colorFaded
    : theme.components?.Navigation.color;

  if (pageReference) {
    const { __typename, slug, isRecipe } =
      pageReference as ContentfulNavigationPageReference;
    fullHref = getHrefByPageTypeAndSlug(__typename, slug, isRecipe);
  } else if (externalLink) {
    fullHref = externalLink;
  }
  return (
    <MenuItem key={`desktop-${internal}`}>
      {selected && <NavHighlight />}
      <MUILink
        sx={{ height: "100%", display: "flex", alignItems: "center" }}
        variant="body1"
        target={openNewTab ? "_blank" : "_self"}
        color={textColor}
        underline="none"
        href={fullHref}
      >
        {item.label}
      </MUILink>
    </MenuItem>
  );
};
export default NavDesktop;
