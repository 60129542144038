"use client";

import { styled, useTheme } from "@mui/material/styles";

import React from "react";

import { ISiteSettingsFields } from "../../@types/generated/contentful";
import SimpleTextRenderer from "../content-section/helper/simpleTextRenderer";
import Send from "../icons/send.svg";

const StyledBanner = styled("div")(() => {
  const theme = useTheme();
  return {
    backgroundColor: theme.components?.Banner.backgroundColor,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    "> p": {
      margin: 0,
      display: "inline-block",
      fontSize: theme.typography.caption.fontSize,
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
    a: {
      color: ` ${theme.palette.softBlack.main} !important`,
    },
  };
});

type Icons = NonNullable<IBannerProps["icon"]>;

// Make sure to map all possible icons
const IconMap: Record<Icons, typeof Send> = {
  send: Send,
  info: Send,
};

interface IBannerProps {
  text: ISiteSettingsFields["bannerText"];
  icon?: ISiteSettingsFields["bannerIcon"];
}
const Banner = ({ text, icon }: IBannerProps) => {
  const theme = useTheme();
  const Icon = icon ? IconMap[icon] : null;

  return (
    <StyledBanner>
      {Icon && (
        <Icon
          style={{
            width: "16px",
            height: "16px",
            marginBottom: "-2px",
            marginRight: theme.spacing(1),
          }}
        />
      )}
      {text && <SimpleTextRenderer richTextDocument={text} />}
    </StyledBanner>
  );
};

export default Banner;
