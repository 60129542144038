import { Add, Remove } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function CartAmountToggle(props: {
  amount: number;
  setCartAmount: (amount: number) => void;
}) {
  const theme = useTheme();
  const { setCartAmount, amount } = props;
  const textField = (
    <TextField
      variant="standard"
      value={amount}
      InputProps={{
        disableUnderline: true,
      }}
      onChange={(ev) => setCartAmount(Math.max(0, parseInt(ev.target.value)))}
      inputProps={{
        min: 0,
        style: { textAlign: "center", padding: 0, border: "none" },
      }}
      sx={{
        textAlign: "center",
        minWidth: "2em",
        width: "1em",
        m: 0,
        p: "0px",
        border: "none",
        borderLeft: `1px solid ${theme.palette.softBlack.light}`,
        borderRight: `1px solid ${theme.palette.softBlack.light}`,
      }}
    />
  );
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          border: `1px solid ${theme.palette.softBlack.light}`,
          borderRadius: "32px",
          boxSizing: "border-box",
          px: theme.spacing(0),

          mb: theme.spacing(1),
        }}
      >
        <IconButton
          onClick={() => {
            setCartAmount(amount - 1);
          }}
          size="small"
          color="inherit"
        >
          <Remove sx={{ fontSize: "16px" }} />
        </IconButton>
        {textField}
        <IconButton
          size="small"
          onClick={() => setCartAmount(amount + 1)}
          color="inherit"
        >
          <Add sx={{ fontSize: "16px" }} />
        </IconButton>
      </Box>
      <Box sx={{ cursor: "pointer" }} onClick={() => setCartAmount(0)}>
        <Typography
          sx={{ color: theme.palette.softBlack.main }}
          variant="body2"
        >
          Entfernen
        </Typography>
      </Box>
    </Box>
  );
}
