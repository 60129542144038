import { ShopifyProductWithVariant } from "../../@types/shopifyProductWithVariant";
import { DataLayerItem, clearDataLayer, getDataLayerItem } from "./common";

export interface CourseListType {
  id: number;
  name: string;
}

const homepageList: CourseListType = { id: 0, name: "Homepage" };
const headerList: CourseListType = { id: 1, name: "Header" };
const courseOverviewList: CourseListType = { id: 2, name: "CourseOverview" };
const otherPageList: CourseListType = { id: 3, name: "Other" };

export const COURSE_LISTS = {
  HOMEPAGE: homepageList,
  HEADER: headerList,
  COURSE_OVERVIEW: courseOverviewList,
  OTHER: otherPageList,
};

export interface SelectItemEvent {
  event: string;
  ecommerce: {
    item_list_id: number;
    item_list_name: string;
    currency: string;
    totalValue: number;
    items: DataLayerItem[];
  };
}

const EVENT_NAME = "select_item";

const pushDataLayerSelectItem = (
  sourceItemList: CourseListType,
  shopifyProduct: ShopifyProductWithVariant
) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    clearDataLayer();
    window.dataLayer.push(getDataLayerEvent(sourceItemList, shopifyProduct));
  }
};

function getDataLayerEvent(
  sourceItemList: CourseListType,
  shopifyProduct: ShopifyProductWithVariant
): SelectItemEvent {
  return {
    event: EVENT_NAME,
    ecommerce: {
      item_list_id: sourceItemList.id,
      item_list_name: sourceItemList.name,
      currency: shopifyProduct?.minPrice.currencyCode,
      totalValue: shopifyProduct?.price.amount,
      items: [getDataLayerItem(shopifyProduct)],
    },
  };
}

export default pushDataLayerSelectItem;
